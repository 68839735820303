.raveFeedLogo{
    position: absolute;
    display: block;
    right: 2%;
    bottom: 2%;
    z-index: 103;
}

.raveFeedName{
    font-size: 25px;
    color: white;
    font-weight: bold;
}