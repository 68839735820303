@font-face {font-family: "Helvetica Extended BQ"; src: url("//db.onlinewebfonts.com/t/75056347b15878b7ae652ac7de87ac14.eot"); src: url("//db.onlinewebfonts.com/t/75056347b15878b7ae652ac7de87ac14.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/75056347b15878b7ae652ac7de87ac14.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/75056347b15878b7ae652ac7de87ac14.woff") format("woff"), url("//db.onlinewebfonts.com/t/75056347b15878b7ae652ac7de87ac14.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/75056347b15878b7ae652ac7de87ac14.svg#Helvetica Extended BQ") format("svg"); }
@import url("//db.onlinewebfonts.com/c/9d942e0ff1014c476713a855710ce309?family=Lato");
@font-face {font-family: "LatoLight"; src: url("//db.onlinewebfonts.com/t/5a012dbdb52ab9b13e4d66f1bc9b83c0.eot"); src: url("//db.onlinewebfonts.com/t/5a012dbdb52ab9b13e4d66f1bc9b83c0.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/5a012dbdb52ab9b13e4d66f1bc9b83c0.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/5a012dbdb52ab9b13e4d66f1bc9b83c0.woff") format("woff"), url("//db.onlinewebfonts.com/t/5a012dbdb52ab9b13e4d66f1bc9b83c0.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/5a012dbdb52ab9b13e4d66f1bc9b83c0.svg#Lato") format("svg"); }

.titre{
    position: relative;
    z-index: 1039;
    white-space: nowrap;
    color: white;
    font-size: 4.5vw;
    font-family: Helvetica Extended BQ;
    font-weight: bold;
    margin-bottom: 2vh;
    width: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.titre p{
    margin-bottom: -2vw;
}

@media screen and (max-width:965px){
    .mainTitle{
        text-align: left !important;
    }
    
}
.mainTitle{
    width: 100%;
    text-align: end;
}

.rightJustify{
    display: block;
    text-align: right;
    
}

#By{
    font-family: LatoLight;
    font-size: 2vw;
    font-style: italic;
    font-weight: lighter;
}

#raveFeedTop{
    font-family: Lato;
    font-size: 3vw;
    font-weight: bold;
}

@media screen and (max-width:962px){
    .titre{
        white-space: nowrap;
        font-size: 5vw !important;
    }
}

.homeNavBar li{
    display: inline;
    padding : 20px;
}
.homeNavBar {
    text-align: center;
    font-weight: 900;
    font-size: 30px;
}

.homeNavBar a{
    position: relative;
    color : white;
    text-decoration: none;
}

.homeNavBar a:visited{
    color : white;
    text-decoration: none;
}

.homeNavBar a:after{
    content: '';
    position: absolute;
    width: 0; height: 3px;
    display: block;
    margin-top: 5px;
    right: 0;
    background: #fff;
    transition: width .2s ease;
    -webkit-transition: width .2s ease;
  }

  .homeNavBar a:hover:after{
    width: 100%;
    left: 0;
    background: #fff;
  }

  