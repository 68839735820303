


body{
    background-color: black;
    background-image: url("http://ravefeed.com/wp-content/fontLegends.jpeg");
    background-size: cover;
    background-attachment: fixed;
    font-family: "Lato";
    padding : 2%;
}

.bgImg{
    z-index: -10;
}

.bgImageLeft{
    position: absolute;
    width: 25vw;
    height: auto;
    border: 1px solid white;
}

@media screen and (max-width:1478px){
    .bgImageLeft{
        top: 10vh !important;
    }
}

.bgImageRight{
    position: absolute;
    width: 25vw;
    height: auto;
    border: 1px solid white;
}