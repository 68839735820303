.thanks-div{
    margin-top: 5%;
    margin-bottom: 5%;
    padding: 20px;
    text-align: center;
    background-color: black;
    color: white;
    border: 2px solid white;
    z-index: 1000;
    border-radius: 20px;
}

.social-networks{
    padding-left: 0px;
}

hr{
    background-color: white;
}

.social-networks li{
    display: inline-block;
    margin: 10px;
}
.icon{
    background-repeat: no-repeat;
    width: 50px;
    height: 50px;
    background-position: center center;
    background-size: contain;
    display: inline-block;
}


.icon-facebook{
    background-image: url("https://www.flaticon.com/svg/static/icons/svg/733/733547.svg");
    
}

.icon-twitter{
    background-image: url("https://www.flaticon.com/svg/static/icons/svg/1409/1409937.svg");
}


.icon-instagram{
    background-image: url("https://www.flaticon.com/svg/static/icons/svg/174/174855.svg");
    
}


.shareLink{
    padding-top: 5px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 5px;
    border-radius: 10px;
    background-color: black;
    border: 1px solid white;
    font-size: 17px;
    color : white;
    width: 80%;
    animation-timing-function: linear;
}

.shareLink:hover{
    background-color: white;
    border: 1px solid white;
    font-size: 17px;
    color : black;
    width: 80%;
    text-decoration: none;
}



.thanks-title{
    font-family: "Helvetica Neue";
    font-size: xx-large;
    font-style: bold;
}

.thanks-text{
    font-family: "Helvetica Neue";
    font-size: 17px;
    font-style: bold;
}




