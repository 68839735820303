.welcome-col{
    margin-top: 5%;
    margin-bottom: 5%;
    padding: 20px;
    text-align: left;
    background-color: black;
    color: white;
    border: 2px solid white;
    z-index: 1000;
    border-radius: 20px;
    font-size: large;
    /* font-weight: ; */
    font-family: "Helvetica Neue";
}

.voteButtonDiv{
    text-align: center;
}

#voteButton{
    padding-top: 5px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 5px;
    border-radius: 10px;

    margin-left: auto;
    margin-right: auto;

    background-color: black;
    border: 1px solid white;
    font-size: 24px;
    color : white;
    width: 100%;
    animation-timing-function: linear;
}

#voteButton:hover{
    background-color: white;
    border: 1px solid white;
    font-size: 24px;
    color : black;
    width: 100%;
    text-decoration: none;
}
