@import url(//db.onlinewebfonts.com/c/9d942e0ff1014c476713a855710ce309?family=Lato);





body{
    background-color: black;
    background-image: url("http://ravefeed.com/wp-content/fontLegends.jpeg");
    background-size: cover;
    background-attachment: fixed;
    font-family: "Lato";
    padding : 2%;
}

.bgImg{
    z-index: -10;
}

.bgImageLeft{
    position: absolute;
    width: 25vw;
    height: auto;
    border: 1px solid white;
}

@media screen and (max-width:1478px){
    .bgImageLeft{
        top: 10vh !important;
    }
}

.bgImageRight{
    position: absolute;
    width: 25vw;
    height: auto;
    border: 1px solid white;
}
@font-face {font-family: "Helvetica Extended BQ"; src: url("//db.onlinewebfonts.com/t/75056347b15878b7ae652ac7de87ac14.eot"); src: url("//db.onlinewebfonts.com/t/75056347b15878b7ae652ac7de87ac14.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/75056347b15878b7ae652ac7de87ac14.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/75056347b15878b7ae652ac7de87ac14.woff") format("woff"), url("//db.onlinewebfonts.com/t/75056347b15878b7ae652ac7de87ac14.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/75056347b15878b7ae652ac7de87ac14.svg#Helvetica Extended BQ") format("svg"); }
@font-face {font-family: "LatoLight"; src: url("//db.onlinewebfonts.com/t/5a012dbdb52ab9b13e4d66f1bc9b83c0.eot"); src: url("//db.onlinewebfonts.com/t/5a012dbdb52ab9b13e4d66f1bc9b83c0.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/5a012dbdb52ab9b13e4d66f1bc9b83c0.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/5a012dbdb52ab9b13e4d66f1bc9b83c0.woff") format("woff"), url("//db.onlinewebfonts.com/t/5a012dbdb52ab9b13e4d66f1bc9b83c0.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/5a012dbdb52ab9b13e4d66f1bc9b83c0.svg#Lato") format("svg"); }

.titre{
    position: relative;
    z-index: 1039;
    white-space: nowrap;
    color: white;
    font-size: 4.5vw;
    font-family: Helvetica Extended BQ;
    font-weight: bold;
    margin-bottom: 2vh;
    width: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.titre p{
    margin-bottom: -2vw;
}

@media screen and (max-width:965px){
    .mainTitle{
        text-align: left !important;
    }
    
}
.mainTitle{
    width: 100%;
    text-align: end;
}

.rightJustify{
    display: block;
    text-align: right;
    
}

#By{
    font-family: LatoLight;
    font-size: 2vw;
    font-style: italic;
    font-weight: lighter;
}

#raveFeedTop{
    font-family: Lato;
    font-size: 3vw;
    font-weight: bold;
}

@media screen and (max-width:962px){
    .titre{
        white-space: nowrap;
        font-size: 5vw !important;
    }
}

.homeNavBar li{
    display: inline;
    padding : 20px;
}
.homeNavBar {
    text-align: center;
    font-weight: 900;
    font-size: 30px;
}

.homeNavBar a{
    position: relative;
    color : white;
    text-decoration: none;
}

.homeNavBar a:visited{
    color : white;
    text-decoration: none;
}

.homeNavBar a:after{
    content: '';
    position: absolute;
    width: 0; height: 3px;
    display: block;
    margin-top: 5px;
    right: 0;
    background: #fff;
    transition: width .2s ease;
    -webkit-transition: width .2s ease;
  }

  .homeNavBar a:hover:after{
    width: 100%;
    left: 0;
    background: #fff;
  }

  
.raveFeedLogo{
    position: absolute;
    display: block;
    right: 2%;
    bottom: 2%;
    z-index: 103;
}

.raveFeedName{
    font-size: 25px;
    color: white;
    font-weight: bold;
}
.votePlaceholder{
    color : white;

}

.modal-backdrop {
      /* Add the blur effect */
  filter: blur(8px);
  -webkit-filter: blur(8px);
  background-color: azure;
}

.modal-content {
    background-color: black;
}
.modal-content div{
    text-align: center;
    background-color: black;
}
.modal-content {
    color : white;
}
.loader .raveFeedLogo{
    background-color: black;
}
.loader div{
    background-color: white;
    margin: 10px;
}

.voteContainer{
    border-radius: 20px;
    padding: 0% 6% 5% 6%;
    text-align: center;
    background-color: black;
    border: 2px solid white;
    display: block;
    margin-right: auto;
    margin-left: auto;
    margin-top : 7%;
    margin-bottom: 2%;
}

@media screen and (min-width:1024px){
    .voteContainer{
        margin-top : 2% !important;
    }
}
.voteForm{

    text-align: center;
}

.voteButton{
    background-color: black;
    border: 1px solid white;
    font-size: 24px;
    color : white;
    width: 100%;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
}

.voteButton:hover{
    background-color: white;
    border: 1px solid white;
    font-size: 24px;
    color : black;
    width: 100%;
}

.list-group-item span{
    font-size: 24px;
    font-weight: bold;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle{
    color: white;
    background-color: black;
    border-color: white;
}


.voteInput{
    width: 100%;
    margin-top: 5vh;
    margin-bottom: 5vh;
    display: block;
    color : white;
    font-size: 24px;
    background-color: #0000 !important;
    border: none;
    border-bottom: 2px solid white;
}

.voteInput::-webkit-input-placeholder{
    font-size: 24px;
}

.voteInput:-ms-input-placeholder{
    font-size: 24px;
}

.voteInput::-ms-input-placeholder{
    font-size: 24px;
}

.voteInput::placeholder{
    font-size: 24px;
}
.thanks-div{
    margin-top: 5%;
    margin-bottom: 5%;
    padding: 20px;
    text-align: center;
    background-color: black;
    color: white;
    border: 2px solid white;
    z-index: 1000;
    border-radius: 20px;
}

.social-networks{
    padding-left: 0px;
}

hr{
    background-color: white;
}

.social-networks li{
    display: inline-block;
    margin: 10px;
}
.icon{
    background-repeat: no-repeat;
    width: 50px;
    height: 50px;
    background-position: center center;
    background-size: contain;
    display: inline-block;
}


.icon-facebook{
    background-image: url("https://www.flaticon.com/svg/static/icons/svg/733/733547.svg");
    
}

.icon-twitter{
    background-image: url("https://www.flaticon.com/svg/static/icons/svg/1409/1409937.svg");
}


.icon-instagram{
    background-image: url("https://www.flaticon.com/svg/static/icons/svg/174/174855.svg");
    
}


.shareLink{
    padding-top: 5px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 5px;
    border-radius: 10px;
    background-color: black;
    border: 1px solid white;
    font-size: 17px;
    color : white;
    width: 80%;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
}

.shareLink:hover{
    background-color: white;
    border: 1px solid white;
    font-size: 17px;
    color : black;
    width: 80%;
    text-decoration: none;
}



.thanks-title{
    font-family: "Helvetica Neue";
    font-size: xx-large;
    font-style: bold;
}

.thanks-text{
    font-family: "Helvetica Neue";
    font-size: 17px;
    font-style: bold;
}





.welcome-col{
    margin-top: 5%;
    margin-bottom: 5%;
    padding: 20px;
    text-align: left;
    background-color: black;
    color: white;
    border: 2px solid white;
    z-index: 1000;
    border-radius: 20px;
    font-size: large;
    /* font-weight: ; */
    font-family: "Helvetica Neue";
}

.voteButtonDiv{
    text-align: center;
}

#voteButton{
    padding-top: 5px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 5px;
    border-radius: 10px;

    margin-left: auto;
    margin-right: auto;

    background-color: black;
    border: 1px solid white;
    font-size: 24px;
    color : white;
    width: 100%;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
}

#voteButton:hover{
    background-color: white;
    border: 1px solid white;
    font-size: 24px;
    color : black;
    width: 100%;
    text-decoration: none;
}

