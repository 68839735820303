.votePlaceholder{
    color : white;

}

.modal-backdrop {
      /* Add the blur effect */
  filter: blur(8px);
  -webkit-filter: blur(8px);
  background-color: azure;
}

.modal-content {
    background-color: black;
}
.modal-content div{
    text-align: center;
    background-color: black;
}
.modal-content {
    color : white;
}
.loader .raveFeedLogo{
    background-color: black;
}
.loader div{
    background-color: white;
    margin: 10px;
}

.voteContainer{
    border-radius: 20px;
    padding: 0% 6% 5% 6%;
    text-align: center;
    background-color: black;
    border: 2px solid white;
    display: block;
    margin-right: auto;
    margin-left: auto;
    margin-top : 7%;
    margin-bottom: 2%;
}

@media screen and (min-width:1024px){
    .voteContainer{
        margin-top : 2% !important;
    }
}
.voteForm{

    text-align: center;
}

.voteButton{
    background-color: black;
    border: 1px solid white;
    font-size: 24px;
    color : white;
    width: 100%;
    animation-timing-function: linear;
}

.voteButton:hover{
    background-color: white;
    border: 1px solid white;
    font-size: 24px;
    color : black;
    width: 100%;
}

.list-group-item span{
    font-size: 24px;
    font-weight: bold;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle{
    color: white;
    background-color: black;
    border-color: white;
}


.voteInput{
    width: 100%;
    margin-top: 5vh;
    margin-bottom: 5vh;
    display: block;
    color : white;
    font-size: 24px;
    background-color: #0000 !important;
    border: none;
    border-bottom: 2px solid white;
}

.voteInput::placeholder{
    font-size: 24px;
}